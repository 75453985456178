import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import  BtnPrimary  from '../components/buttons/ButtonRounded';
import Seo from '../components/seo';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  GridAlSophro,
  ColGauche,
  ColDroite,
  Text
} from '../components/Elements';

export const maintenanceQuery = graphql`
query  maintenanceQuery($locale: String!){
     page: datoCmsPageMaintenance(locale: {eq: $locale}) {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      illustration {
        gatsbyImageData(
          placeholder: BLURRED,
          forceBlurhash: false,           
        )
      }
    }
  }
`;

const MaintenancePage = ({data}) => {
  
  const { titre, contenu, seoMetaTags, illustration } = data.page;

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
       
        <PageInner>
          <GridAlSophro>
            <ColGauche>
              <GatsbyImage image={illustration.gatsbyImageData} alt={titre} />
            </ColGauche>
  
            <ColDroite>
              <PageTitle>{titre}</PageTitle>
              <Text dangerouslySetInnerHTML={{ __html: contenu }} />
              <BtnPrimary to="/blog">
              Accès au blog
              </BtnPrimary>
              
            </ColDroite>
          </GridAlSophro>
        </PageInner>
      </PageWrapper>
    </Fragment>
 );
}

export default  MaintenancePage